<!--
 * @Author: lbh
 * @Date: 2023-08-08 16:57:57
 * @LastEditors: lbh
 * @LastEditTime: 2023-11-21 14:12:03
 * @Description: file content
-->
<template>
  <div class="edit-suspension-module-box">
    <div
      v-for="(item,index) in configs.items"
      :key="index"
      class="cell-b"
    >
      <div class="cell-b">

        <!-- 操作 -->
        <el-button-group class="btn-group">
          <el-button
            type="primary"
            icon="el-icon-upload2"
            :disabled="index == 0"
            size="mini"
            @click="itemCheck(index,index-1)"
          ></el-button>
          <el-button
            type="success"
            icon="el-icon-download"
            :disabled="index == configs.items.length-1"
            size="mini"
            @click="itemCheck(index,index+1)"
          ></el-button>
          <el-button
            type="danger"
            icon="el-icon-delete"
            size="mini"
            :disabled="configs.items.length == 1"
            @click="itemDel(index)"
          ></el-button>
          <el-button
            type="warning"
            icon="el-icon-plus"
            size="mini"
            :disabled="configs.items.length == 5"
            @click="itemAdd(index)"
          ></el-button>
        </el-button-group>
        <selfCell title="圖標">
          <selfUpload
            v-model="item.img"
            type="default"
            @change="setValue('items')"
          />
        </selfCell>
        <selfCell
          title="大數字"
          :column="true"
        >
          <el-input
            v-for="lItem in language"
            :key="lItem.code"
            v-model="item[`${lItem.code=='HK'?'':lItem.code}number`]"
            :placeholder="`請輸入大數字(會翻滾)-${lItem.name}`"
            @input="setValue('items')"
          />
        </selfCell>
        <selfCell
          title="小字"
          :column="true"
        >
          <el-input
            v-for="lItem in language"
            :key="lItem.code"
            v-model="item[`${lItem.code=='HK'?'':lItem.code}desc`]"
            :placeholder="`請輸入小字-${lItem.name}`"
            @input="setValue('items')"
          />
        </selfCell>
        <selfCell
          title="描述"
          :column="true"
        >
          <el-input
            v-for="lItem in language"
            :key="lItem.code"
            v-model="item[`${lItem.code=='HK'?'':lItem.code}content`]"
            :placeholder="`請輸入描述-${lItem.name}`"
            @input="setValue('items')"
          />
        </selfCell>
        <selfCell title="滾動時長">
          <el-select
            v-model="item.time"
            @input="setValue('items')"
          >
            <el-option
              label="1S"
              :value="1000"
            ></el-option>
            <el-option
              label="2S"
              :value="2000"
            ></el-option>
            <el-option
              label="3S"
              :value="3000"
            ></el-option>
            <el-option
              label="4S"
              :value="4000"
            ></el-option>
          </el-select>
        </selfCell>
      </div>
    </div>
  </div>
</template>

<script>
import langEditMixin from '../../mixins/langEdit'
import editModuleMixin from "../../mixins/editModuleMixin"
export default {
  name: "edit-suspension-module",
  mixins: [langEditMixin, editModuleMixin],
  methods: {
    // 切換順序
    itemCheck (index, newIndex) {
      let items = this.configs.items;
      let _ = items.splice(index, 1);
      items.splice(newIndex, 0, _[0])
      this.$emit('setValue', { key: 'items', value: items });
    },
    // 刪除
    itemDel (index) {
      let items = this.configs.items;
      items.splice(index, 1);
      this.$emit('setValue', { key: 'items', value: items });
    },
    // 新增
    itemAdd (index) {
      let items = this.configs.items;
      items.splice(index + 1, 0, this.$util.object.clone(items[index]))
      this.$emit('setValue', { key: 'items', value: items });
    }
  }
}
</script>

<style lang="less" scoped>
</style>